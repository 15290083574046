
/* Links */
a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.title-page {
  padding-top: 70px; /* Adjust this value based on the height of your navbar */
}

.header {
  text-align: center;
  background-color: #f4f4f4;
  padding: 20px;
}

.header h1 {
  margin: 0;
  font-size: 2.5em;
  color: #333;
}

.header p {
  margin: 10px 0 0;
  font-size: 1.2em;
  color: #666;
}
.info-boxes {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  padding: 0 20px;
}


h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin: 1rem 0;
  padding: 0 1rem;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, color 0.3s;
}

h1:hover {
  color: #0072ff;
  transform: scale(1.05);
}

.skeleton-loader {
  width: 100%;
  background-color: #e0e0e0;
}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 50px 0;
}

.loading-box {
  width: 120px;
  height: 120px;
  background-color: #ccc;
  animation: pulse 1.2s infinite ease-in-out;
  border-radius: 8px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

